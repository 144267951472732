import { createSlice } from "@reduxjs/toolkit";
import { SearchAppBaseText, defaultBaseText } from "./text-interface";

let filtersLabelText = (window as any).bootstrap?.filtersLabelText;
let resetFiltersLabelText = (window as any).bootstrap?.resetFiltersLabelText;
let sortByMostRelevantText = (window as any).bootstrap?.sortByMostRelevantText;
let sortByDateNewestText = (window as any).bootstrap?.sortByDateNewestText;
let sortByDateOldestText = (window as any).bootstrap?.sortByDateOldestText;
let sortByTitleAToZText = (window as any).bootstrap?.sortByTitleAToZText;
let sortByTitleZToAText = (window as any).bootstrap?.sortByTitleZToAText;
let searchButtonText = (window as any).bootstrap?.searchButtonText;
let searchFieldPlaceholderText = (window as any).bootstrap
  ?.searchFieldPlaceholderText;
let popularSearchesText = (window as any).bootstrap?.popularSearchesText;
let noResultsText = (window as any).bootstrap?.noResultsText;
let pageId = (window as any).bootstrap?.pageId;
let siteId = (window as any).bootstrap?.currentSiteId;
let siteData = (window as any).bootstrap?.siteData;
let popularSearches = (window as any).bootstrap?.popularSearches;
let sidebarAds = (window as any).bootstrap?.sidebarAds;
let noFilterText = (window as any).bootstrap?.noFilterText;
let showFilterCounts = (window as any).bootstrap?.showFilterCounts;

export interface SearchBaseSettings extends SearchAppBaseText {
  layoutBreakpoint: string;
}

const initialState = {
  ...defaultBaseText,
  filtersLabelText,
  resetFiltersLabelText,
  sortByMostRelevantText,
  sortByDateNewestText,
  sortByDateOldestText,
  sortByTitleAToZText,
  sortByTitleZToAText,
  searchButtonText,
  searchFieldPlaceholderText,
  popularSearchesText,
  noResultsText,
  pageId,
  siteId,
  siteData,
    popularSearches,
    sidebarAds,
    noFilterText,
    showFilterCounts
};

const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    initializeSettings(state) {
      return {
        ...initialState,
      };
    },
  },
});

export const { initializeSettings } = settingSlice.actions;
export default settingSlice.reducer;
